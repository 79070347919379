import React, { useLayoutEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import parse from 'html-react-parser'
import { useInView } from 'react-hook-inview'

const StyledBranches = styled.div``

const StyledCustomLink = styled(CustomLink)`
  &:hover {
    text-decoration: none;
  }
`

const Branch = styled(motion.div)`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  height: 300px;
  width: 100%;
  border-radius: 20px;

  h2 {
    font-size: ${props => props.theme.font.size.ms};
    font-weight: ${props => props.theme.font.weight.xl};
    padding-bottom: 20px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background: ${props => props.theme.color.gradient.main};
      width: 120%;
      height: 3px;
      left: -10%;
      border-radius: 1.5px;
      bottom: 5px;
    }
  }

  p {
    font-size: ${props => props.theme.font.size.s};
    text-align: center;
    padding: 0 20px;
    margin-bottom: 0;
  }
`

const BranchesRow = styled.div`
  margin-left: -80px;
  margin-right: -80px;

  @media screen and (max-width: 991px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const SolutionShadow = styled(motion.div)`
  position: absolute;
  background: ${props => props.theme.color.gradient.third};
  z-index: 1;
  border-radius: 23px;
`

const SolutionInner = styled.div`
  background-color: ${props => props.theme.color.face.light};
  position: relative;
  z-index: 2;
  border-radius: 20px;
`

const Branches = ({ className }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.1,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  const {
    branches: {
      nodes: branches
    }
  } = useStaticQuery(graphql`{
    branches: allWordpressPage(filter: {parent_element: {wordpress_id: {eq: 112}}}, sort: {fields: date}) {
      nodes {
        ...PreviewBranchesFrag
      }
    }
  }`)

  return (
    <StyledBranches ref={ref} className={`container ${className ? `${className}` : ``}`}>
      <BranchesRow className="row py-lg-5 justify-content-center justify-content-lg-between">
        {branches.map((branch, index) => (
          <StyledCustomLink className="px-lg-0 px-md-3 py-lg-0 py-3 col-sm-12 col-md-6 col-lg-2" to={branch.path} key={branch.wordpress_id}>
            <Branch
              initial="rest"
              whileHover="hover"
              whileTap="stress"
              animate={isVisible || view ? "init" : "rest"}
              variants={{ init: { opacity: 1, y: 0, transition: { type: 'spring', duration: 0.5, delay: index * 0.1 } }, stress: { scale: 0.95 }, rest: { opacity: 0.3, y: 50, borderWidth: '0px', scale: 1 }, hover: { borderWidth: '3px', scale: 1.05 } }}
            >
              <SolutionInner className="h-100 p-lg-0 p-5 d-flex align-items-center justify-content-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <h2 className="float-left text-center">{branch.title}</h2>
                  </div>
                  {parse(branch.acf.preview.description)}
                </div>
              </SolutionInner>
              <SolutionShadow
                variants={{
                  rest: {
                    zIndex: -1,
                    opacity: 0,
                    height: 'calc(100% - 4px)',
                    width: 'calc(100% - 4px)',
                    left: '2px',
                    top: '2px'
                  },
                  hover: {
                    opacity: 1,
                    height: 'calc(100% + 8px)',
                    width: 'calc(100% + 8px)',
                    left: '-4px',
                    top: '-4px',
                  }
                }}
              />
            </Branch>
          </StyledCustomLink>
        ))}
      </BranchesRow>
    </StyledBranches>
  )
}

export default Branches